<template>
  <div class="event">
    <div class="container-fluid event-banner">
      <img src="../assets/event-banner.png" alt="" />
    </div>
    <div class="container-fluid cta-strip">
      <div class="container">
        <div class="date-pop">
          12 Jun
        </div>
        <div class="row">
          <div class="col-md-8">
            <h3>Introduction To Digital World</h3>
            <span>Webinar</span>
          </div>
          <div class="col-md-4">
            <div class="price-box">
              <h3>₹4500</h3>
              <button class="btn cta-primary">Book Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container content-wrapper">
      <h4>About</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt
        expedita laudantium provident doloremque perferendis dolore fugit
        voluptates eos cumque nihil debitis, libero ut molestiae fuga dolor,
        minus veniam, excepturi hic. Dolore dicta molestias commodi suscipit,
        nihil sint quaerat reiciendis quam sunt ut soluta iste dolores voluptas
        repellendus temporibus. Quam, expedita.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt
        expedita laudantium provident doloremque perferendis dolore fugit
        voluptates eos cumque nihil debitis, libero ut molestiae fuga dolor,
        minus veniam, excepturi hic. Dolore dicta molestias commodi suscipit,
        nihil sint quaerat reiciendis quam sunt ut soluta iste dolores voluptas
        repellendus temporibus. Quam, expedita.
      </p>

      <h4>About</h4>

      <div class="structure-wrapper">
        <div class="row">
          <div class="col-md-4">
            <h1>1</h1>
            <h3>Introduction</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
              culpa quam adipisci explicabo. Nihil deleniti accusamus molestias
              optio eius! Molestiae?
            </p>
          </div>
          <div class="col-md-4">
            <h1>2</h1>
            <h3>Session - 1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
              culpa quam adipisci explicabo. Nihil deleniti accusamus molestias
              optio eius! Molestiae?
            </p>
          </div>
          <div class="col-md-4">
            <h1>3</h1>
            <h3>Session - 2</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
              culpa quam adipisci explicabo. Nihil deleniti accusamus molestias
              optio eius! Molestiae?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicProfile",
  components: {}
};
</script>

<style lang="scss">
@import "@/style/event.scss";
</style>
